.info-wrapper {
  margin-bottom: 1rem;
}
.info-wrapper-item {
  flex-basis: 48%;
}
.info-wrapper-input {
  display: flex;
}
@media screen and (max-width: 960px) {
  .info-wrapper-input {
    flex-direction: column;
  }
  /* .info-wrapper-item { */
  /*   text-decoration: underline; */
  /* } */
  .input {
    /* width: 200px; */
  }
}

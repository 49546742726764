.is-danger {
  color: red;
}
.space:after {
  content: "\200b";
}

h1,h2,h3,h4,h5,h6,th{
    color:var(--primary-color-dark);
}

.bg-lb {
  background-color: var(--lb) !important;
}

/* Flex */
.block {
  display: block;
}
.none {
  display: none;
}
.inline-block {
  display: inline-block;
}
.flex {
   display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.align-self-center {
  align-self: center;
}
.align-content-center {
  align-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}
/* Padding */
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.px-0 {
  padding: 0.5rem 0rem;
}
.px-1 {
  padding: 1rem 0rem;
}
.px-2 {
  padding: 2rem 0rem;
}
.pt-0 {
  padding-top: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pb-0 {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pl-2 {
  padding-left: 2rem;
}

.pr-0 {
  padding-right: 0.5rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}

/* Margin */
.mr-40 {
  margin-right: 40px;
}
.m-0 {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}

.mt-0 {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mb-0 {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}

.mr-0 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}

.ml-0 {
  margin-left: 0.5rem;
}
.ml-025 {
  margin-left: 0.25rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}

.mv-0 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m-0-auto {
  margin: 0 auto;
}
.m-auto {
  margin: auto;
}
.mh-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.min-wid-form {
    min-width:600px ;
}
/* Text Transformation */
.capitalize {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}

/* Button */
.submit-btn {
  background-color: var(--bc);
  color: var(--bt);
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
}
.cancel-btn {
  background-color: var(--danger);
  color: var(--bt);
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
}
.green-btn {
  background-color: var(--gb);
  color: var(--dt);
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
}
.action-btn {
  background-color: var(--mt);
  color: var(--dt);
  padding: 0.5rem 2rem;
  border: 1.5px solid var(--dt);
  border-radius: 1.5rem;
  margin: 1rem 0.5rem;
}
.action-btn:hover {
    background-color: var(--primary-color);
    color: var(--white);
}
.submit-btn:hover {
    background-color: var(--primary-color-dark);
    color: var(--white);
}
.cancel-btn:hover {
        background-color: darkred;
    color: var(--white);
}
.action-hidden-btn {
  background-color: var(--mt);
  color: var(--dt);
  padding: 0.5rem 2rem;
  border: 1.5px solid var(--dt);
  border-radius: 1.5rem;
  margin: 0rem 0.5rem;
  visibility: hidden;
}
.action-hidden-btn:hover {
  visibility: visible;
}
.scroll-overflow {
  overflow: scroll;
}
.submit-btn:active,
.cancel-btn:active,
.green-btn:active,
.action-btn:active,
.action-btn:active {
  outline: none;
  border: none;
}
button:disabled {
  outline: none;
  border: 2px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
}
.hidden {
  visibility: hidden;
}

/* Form components */
select {
  display: inline-block;
  border: none;
  border-radius: 5px;
  height: 2rem;
  background-color: var(--mb);
  padding: 0.5rem 1rem;

  /* styling */

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}





select.classic {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.modal-container select.classic {
  min-width: 200px;
}

/* select.classic:focus { */
/*   background-image: linear-gradient(45deg, green 50%, transparent 50%), */
/*     linear-gradient(135deg, transparent 50%, green 50%), */
/*     linear-gradient(to right, ccc, #ccc); */
/*   background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, */
/*     calc(100% - 2.5em) 0.5em; */
/*   background-size: 5px 5px, 5px 5px, 1px 1.5em; */
/*   background-repeat: no-repeat; */
/*   border-color: green; */
/*   outline: 0; */
/* } */

.info-box {
  display: flex;
  justify-content: space-between;
}

.button-controller {
  width: 50px;
}

a {
  text-decoration: none;
  color: inherit;
}

.input:disabled {
  background: #dddddd;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--dt);
  border-color: var(--dt);
}
.aspect-ratio {
    aspect-ratio:16/9;
}

select option {text-transform:capitalize}

@media (max-width: 960px) {
    .min-wid-form {
    min-width:300px}
}

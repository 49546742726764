.filter {
  position: fixed;
  background-color: var(--white);
  padding: 2rem;
  margin: 2rem;
  width: 250px;
  border-radius: 00.5rem;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.filter select,
.filter input {
  background-color: var(--blueshade);
  color: #000;
  border: none;
  border-radius: 6px;
  width: 150px;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.dashboard {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* background-color: #fff; */
  /* height: 100vh; */
  max-width: 1400px;
  margin: auto;
}

.dashboard-view {
  /* display: flex;
    height: calc(100vh - 150px); */
  display: flex;
  justify-content: space-around;
  /* width: 100%; */
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 0 15px 100px 20px;
}

.dashboard-cards-holder {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-cards {
  flex-basis: 28%;
  display: flex;
  flex-direction: column;
  background-color: #7a6fbe;
  color: white;
  border-color: black;
  border-radius: 0.1rem;
  box-shadow: black;
  padding: 1rem;
  margin: 1rem 1rem 2rem 0;
}

.dashboard-cards h2,
.dashboard-cards h6 {
  padding: 1rem 0.5rem;
  color: #f1f1f1;
}

.dashboard-table {
  display: table;
  /* border: 1px solid grey; */
  /* border-radius: 0.25rem; */
  /* box-shadow: 0px 1px 2px 0px grey; */
  width: 100%;
  border: none;
}

.dashboard-table .table {
  display: table;
}

.main-dashboard-area {
  /* display: flex;
    flex-direction: column;
     flex-grow: 1; */
  background-color: var(--white);
  /* border-radius: 2rem;
    padding: 2rem 0rem;
    height: 100%;
    margin: 2rem 2rem 2rem 0rem; */
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  height: 100%;
  min-height: 75vh;
  margin: 2rem 2rem 2rem 15rem;
}

.table {
  width: 100%;
  text-align: center;
}

.table td,
.table th {
  padding: 0.75rem;
}
/* tr:nth-child(even) {
    background-color : var(--lb);
} */

table {
  border-collapse: collapse;
}

tr {
  border-bottom: 1pt solid var(--lightGray);
}
.dashboard-title {
  /* padding: 2rem; */
}

.dash-scrolls {
  /* overflow: scroll; */
}
.info-viewer {
  padding: 0rem 2rem;
  overflow: scroll;
}

.jobs-container {
  display: flex;
  justify-content: space-around;
  /* max-width: 1235px; */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 0 15px 100px 20px;
}
.sidemenu {
  display: inline;
  position: relative;

}

.main-display-dashboard {
  display: inline;
  /* width: 70%; */
}

.card-container {
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
}
.card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 3rem 6rem;
      background-color: var(--white);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.course-card {
  display: flex;
  flex-direction: column;
  background-color: var(--mb);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.course-img {
  background-color: var(--bs2);
  width: 200px;
  height: 200px;
  border-radius: 2rem 2rem 0rem 0rem;
}
.card h1 {
  margin-bottom: 3rem;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrap-input {
  display: flex;
  flex-direction: column;
  border: none;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
}
.input {
    width:100%;
  border: none;
  height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--light-input);
}

.input-half {
    width:100%;
 
  border: none;
  height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--light-input);
}

.input:focus,.input-half:focus,.textarea:focus{
    border: 2px solid;
    border-color:var(--primary-color);
 
}

.button-blue {
  background-color: var(--bc);
  color: var(--bt);
  border-radius: 0.5rem;
  height: 2rem;
  border: none;
  padding: 0.5rem 5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.textarea {
    width:100%;
  border: none;
  height: 150px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--light-input);
}

@media screen and (max-width: 960px) {
  .input,
  .textarea {
    max-width: 100%;
  }
}

.step-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height:10px
}
.step{
    margin-right:16px;
    width:60px;
    height:10px;
    border-radius: 10px;
}
.blue-step{
    background-color: darkblue;

}
.gray-step{
    background-color: gray;
    
}

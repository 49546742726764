:root {
  --lb: #eef9fe;
  --dt: #004b79;
  --bc: #2874f0;
  --bt: #ffffff;
  --mb: #ffffff;
  --gb: #29cc97;
  --lightGray: #dfe0eb;
  --danger: red;
  --blueshade: #aec3e3;
  --bs2: #bdd4f9;
  /*lb-light Background*/
  /*dt-dark text*/
  /*bc-button color*/
  /*bt-button text*/
  /*mb- main background*/
  /*gb-greenbutton*/
  /* bs2-blueshade2  */
  /* New Color Theme */
  --white:#fff;
  --primary-background-color:#efefef;
  --primary-color:#2874f0;
  --primary-color-dark:#004b79;
  --light-input:#eef9fe;
}
::-webkit-scrollbar {
  display: none;
}
* {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color:#f7f7f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.height-80 {
  height: 80px;
}

.main-container {
    display: flex;
    justify-content: space-around;
    max-width: 1235px;
   
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding: 0 15px 100px 20px;
}
.sidemenu-jobs {
    display: inline;
    position: relative;
}

.main-display {
    display: inline;
    width: 60%;
}

.main-display-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--white);

    height: 100%;
    width: 100%;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 10%);
    border-radius: 0.5rem;
    margin: 2rem 0rem;
    padding:2rem
}
.jobs-filter-btn {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 100%;
    background-color: yellowgreen;
    position: fixed;
    bottom: 70px;
    right: 30px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: none;
}

.fa-filter {
    color: var(--dt);
}

@media screen and (max-width: 960px) {
    .main-container {
        padding: 0;
    }
    .sidemenu-jobs {
        display: none;
    }
    .main-display {
        width: 100%;
    }
    .jobs-filter-btn {
        display: inline;
    }
}

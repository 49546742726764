.modal-wrapper {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
}
.modal-container {
  min-width: 300px;
  min-height: 300px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  background: var(--white);
  color: #000;
  z-index: 10;
  border-radius: 10px;
}

.delete-modal-container {
  min-width: 300px;
  overflow-y: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  background: var(--white);
  color: #000;
  z-index: 10;
  border-radius: 10px;
}

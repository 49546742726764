.sidebar {
   padding: 2rem 0rem;
  margin: 2rem;
  width: 200px;
  position: fixed;
     border-radius: 0.5rem;
  background-color: var(--white);
}

.sidebar ul {
  list-style-type: none;
}

.sidebar ul li {
  padding: 0.5rem 1rem;
  margin: 0rem;
}

.curriculum {
  padding: 0rem 2rem;
  margin: 1rem 0rem;
}
.curriculum .curriculum-lecture {
  margin-bottom: 1rem;
  border-top-color: transparent;
}
.curriculum .curriculum-card {
  border: 1px solid var(--dt);
  background-color: var(--bs2);
  padding: 2rem;
  margin: 2rem;
}
.curriculum .curriculum-card:hover + div + .action-hidden-btn {
  visibility: visible;
}
.curriculum .curriculum-card .curriculum-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.curriculum .curriculum-card .curriculum-lecture {
  background-color: var(--mb);
  padding: 2rem;
}
.curriculum .curriculum-card:hover .curriculum-section .hidden,
.curriculum .curriculum-card .curriculum-lecture:hover .hidden {
  visibility: visible;
}

.current {
  background-color: #281500;
  color: #281500;
}
/* .curriculum {

} */
.sort-view {
  background-color: var(--mb);
  padding: 1rem;
  width: 400px;
  margin-top: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.segmented-view {
}
.segmented-view .segmented-item {
  padding: 1rem;
  border: 0px;
  border-bottom: 3px;
  border-style: solid;
  border-bottom-color: var(--lightGray);
}

.segmented-view .active {
  border-bottom-color: var(--dt);
}

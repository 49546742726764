.header {
  background: var(--lb);
  height: 75px;
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.header .logo {
  font-family: Roboto;
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 28px;
  color: var(--dt);
}

.header ul {
  list-style-type: none;
}

.header ul li,
.nav-link {
  display: inline;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 18px;
  color: var(--dt);
  text-decoration: none;
}

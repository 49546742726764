:root {
  --primary: #1888ff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: 2px solid var(--primary);
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}
.btn-outline {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: 2px solid var(--primary);
  font-size: 18px;
  color: var(--dt);
  cursor: pointer;
  background-color: transparent;
}

/* .btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    background-color: rgb(28, 27, 27);
    color: #fff;
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
} */

.img-btn {
  background-color: transparent;
  border: none;
  margin-left: 0.5rem;
}
.font-btn {
    background-color: transparent;
    border:none;
    color:var(--dt);
    padding: 8px 10px;
}

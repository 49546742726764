.jobs-display {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 2rem 0rem;
  
}
.job-post-single {
    background-color:var(--white);
    margin-bottom: 1rem;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius:0.5rem;
}


.job-post-single:hover {
    transform: scale(1.01);
    box-shadow: 2px 2px 2px 2px rgba(40,116,240, 0.2);
}

.name {
    font-size: 0.8rem;
    /* color: var(--lightGray); */
}
